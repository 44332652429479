<template>
  <div class="auth">
    <AppCells position="center">
      <img v-if="confirm_success" src="@/assets/img/welcome/email-confirm-icon.svg" alt="Confirm">
      <img v-else src="@/assets/img/welcome/email-reject-icon.svg" alt="Reject">
    </AppCells>
    <template v-if="confirm_success">
      <p class="auth__desc">
        Адрес электронной почты успешно подтвержден.
      </p>
    </template>
    <p v-else class="auth__desc">Возможно, Вы уже подтвердили адрес электронной почты и эта ссылка больше не действительна.</p>
    <AppCells position="center">
      <a href="/" class="btn btn--primary">Войти в кабинет</a>
    </AppCells>
  </div>
</template>

<script>

export default {
  name: 'EmailConfirm',
  data() {
    return {
      confirm_success: true,
    }
  },
  created() {
    this.$store.dispatch('auth/GET_EMAIL_CONFIRM', this.$route.query.token)
      .then(() => {
        this.confirm_success = true
      })
      .catch(() => {
        this.confirm_success = false
      })
  }
}
</script>
